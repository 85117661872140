<!--合同管理-->
<template>
  <div class="panel userList">
    <div class="serch_box">
      <el-form
        :inline="true"
        class="user-search fixed_form"
        size="mini"
        label-width="200px"
      >
        <el-form-item label="Enterprise Name:" size="mini">
          <template>
            <el-select v-model="enterpriseNameCode" popper-class="enterpriseNameClass" filterable placeholder="Please enter company name" @change="changeEnterpriseFn">
              <el-option
                v-for="item in EnterpriseList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="Contract No:" size="mini">
          <template>
            <el-input
              v-model.trim="form.contractNo"
              placeholder="please enter Contract No"
              size="mini"
              maxlength="100"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                style="width:30px"
                @click="showSku"
              />
            </el-input>
          </template>
        </el-form-item>
        <el-form-item label="Enterprise Number:" size="mini">
          <template>
            <el-input
              v-model.trim="form.enterpriseId"
              size="mini"
              oninput="value=value.replace(/[^0-9.]/g,'')" 
              placeholder="please enter Enterprise Number"
              maxlength="9"
            />
          </template>
        </el-form-item>
        <el-form-item v-if="Loadshow" label="Contract Type:" size="mini">
          <template>
            <el-select
              v-model.trim="form.contractType"
              placeholder="Please Choose"
              size="mini"
            >
              <el-option
                v-for="item in contractTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item v-if="Loadshow" label="Sales ERP:" size="mini">
          <template>
            <el-input
              v-model.trim="form.saler"
              size="mini"
              placeholder="please enter Sales ERP"
              maxlength="50"
            />
          </template>
        </el-form-item>
        <el-form-item
          v-if="Loadshow"
          label="Business Audit Status:"
          size="mini"
        >
          <template>
            <el-select
              v-model.trim="form.businessAuditState"
              placeholder="Please Choose"
              size="mini"
            >
              <el-option
                v-for="item in businessAuditStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item
          v-if="Loadshow"
          label="CreditControl Audit Status:"
          size="mini"
        >
          <template>
            <el-select
              v-model.trim="form.creditAuditState"
              placeholder="Please Choose"
              size="mini"
            >
              <el-option
                v-for="item in creditControlAuditStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item v-if="Loadshow" label="Validity Type:" size="mini">
          <template>
            <el-select
              v-model.trim="form.state"
              placeholder="Please Choose"
              size="mini"
            >
              <el-option
                v-for="item in validityTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <!-- <el-form-item v-if="Loadshow" label="Enterprise Name:" size="mini">
          <template>
            <el-input
              v-model.trim="form.enterpriseName"
              placeholder="please enter Enterprise Name"
              size="mini"
              maxlength="100"
            />
          </template>
        </el-form-item> -->
        <el-form-item size="mini">
          <template>
            <el-button type="text" size="mini" @click="loadshowBtn">
              MoreOptions
              <i
                :class="
                  Loadshow == true ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
                style="margin-left: 5px"
              />
            </el-button>
          </template>
        </el-form-item>
      </el-form>
      <div style="margin-left: 20px">
        <el-button type="" size="mini" @click="offBtn">Reset</el-button>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="searchBtn"
        >
          Search
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="addBtn"
        >
          Add
        </el-button>
      </div>
    </div>
    <div class="panel-body">
      <el-table
        :data="tableList"
        fit
        style="width: 100%"
        :header-cell-style="{ background: '#e8f1fe' }"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <img :src="emptyImgSrc" alt="" width="120px" height="110px">
          <p style="color: #969799">No more data</p>
        </template>
        <el-table-column
          label="Contract No"
          min-width="220"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span class="font_blue" @click="conBtn(scope.row)">
              {{ scope.row.contractNo }}
            </span>
            <img
              src="@/assets/copy_icon.png"
              width="14px"
              alt=""
              style="margin-left: 10px; display: inline-block; cursor: pointer;margin-bottom:-2px"
              @click="copy(scope.row.contractNo)"
            >
          </template>
        </el-table-column>
        <el-table-column
          label="Enterprise Number"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.enterpriseId || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Enterprise Name"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.enterpriseName || '--' }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="Contract Type"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template>
            <!-- <div v-for="(items, index) in contractTypeList" :key="index">
              <span v-if="items.value === scope.row.contractType">{{
                items.label
              }}</span>
            </div> -->
            VSP
          </template>
        </el-table-column>
        <el-table-column
          label="Contract validity"
          min-width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.contractStartTime| filterDate }} To {{ scope.row.contractEndTime| filterDate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Bussiness Audit Status"
          min-width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!-- <div
              v-for="(items, index) in businessAuditStatusList"
              v-show="scope.row.businessAuditState != 0"
              :key="index"
            >
              <span v-if="items.value == scope.row.businessAuditState">{{
                items.label||'--'
              }}</span>
            </div>
            <span v-show="scope.row.businessAuditState == 0">Waiting Audit</span> -->
            <font v-if="scope.row.businessAuditState == 1">
              <i class="el-icon-success" style="color: #40c740" />Passed
            </font>
            <font v-else-if="scope.row.businessAuditState == 0">
              <i class="el-icon-time" style="color: black" /> Waiting Audit
            </font>
            <font v-else>
              <i class="el-icon-error" style="color: #fa5050" />Rejected
            </font>
          </template>
        </el-table-column>
        <el-table-column
          label="CreditControl Audit Status"
          min-width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!-- <div
              v-for="(items, index) in creditControlAuditStatusList"
              v-show="scope.row.creditAuditState != 0"
              :key="index"
            >
              <span v-if="items.value == scope.row.creditAuditState">{{
                items.label
              }}</span>
            </div>
            <span v-show="scope.row.creditAuditState == 0">Waiting Audit</span> -->
            <font v-if="scope.row.creditAuditState == 1">
              <i class="el-icon-success" style="color: #40c740" />Passed
            </font>
            <font v-else-if="scope.row.creditAuditState == 0">
              <i class="el-icon-time" style="color: black" /> Waiting Audit
            </font>
            <font v-else>
              <i class="el-icon-error" style="color: #fa5050" />Rejected
            </font>
          </template>
        </el-table-column>
        <el-table-column
          label="Sales ERP"
          min-width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.saler || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Validity Type"
          min-width="140"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!-- <el-switch
              v-model.trim="scope.row.state"
              :active-value="1"
              :inactive-value="0"
              active-color="#ff4949"
              inactive-color="#13ce66"
              :disabled="
                scope.row.businessAuditState != 1 ||
                  scope.row.creditAuditState != 1
              "
              @change="changswitch(scope.row)"
            /> -->
            <i
              :ref="scope.$index"
              :class="
                scope.row.state == 0 ? 'el-icon-success' : 'el-icon-error'
              "
              :style="scope.row.state == 0 ? 'color:#40C740' : 'color:#FA5050'"
              style="margin-right: 5px"
            />
            <span>{{ scope.row.state == 0 ? 'Valid' : 'Invalid' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Operate" width="183" fixed="right">
          <template slot-scope="scope">
            <!-- <el-dropdown trigger="click"> -->
            <!-- <span class="el-dropdown-link font_blue">
                More
                <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown"> -->
            <!-- <el-dropdown-item>
              <span @click="viewUser(scope.row)">View User</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="bundleUser(scope.row)">Bundle User</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="unbindUser(scope.row)">Unbind User</span>
            </el-dropdown-item> -->
            <el-button type="text" @click="viewUser(scope.row)">
              View User
            </el-button>
            <el-button type="text" @click="bundleUser(scope.row)">
              Bundle User
            </el-button>
            <el-button type="text" @click="unbindUser(scope.row)">
              Unbind User
            </el-button>
            <!-- </el-dropdown-menu> -->
            <!-- </el-dropdown> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pageWrapper">
        <el-pagination
          :current-page="listQuery.pageCode"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- 绑定用户 -->
    <el-dialog
      title="Bind the user"
      :visible.sync="dialogFormVisible"
      width="60%"
    >
      <el-form
        ref="postForm"
        :model="postForm"
        :rules="rules"
        label-width="200px"
        :inline="true"
      >
        <el-form-item label="Enterprise User" prop="pin">
          <el-input
            v-model.trim="postForm.pin"
            type
            placeholder="Please enter the Enterprise User"
            maxlength="100"
          />
        </el-form-item>
        <el-form-item label="Contract No" prop="contractNo">
          <el-input
            v-model.trim="postForm.contractNo"
            type
            placeholder="Please enter the Contract No"
            maxlength="100"
          />
        </el-form-item>
        <el-form-item label="User Type:" size="" prop="userType">
          <template>
            <el-select
              v-model.trim="postForm.userType"
              placeholder="Please Choose"
              size=""
            >
              <el-option
                v-for="item in userTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <!-- <el-form-item label="User Flag:" size="mini" prop="userType">
          <template>
            <el-select
              v-model.trim="postForm.userType"
              placeholder="Please Choose"
              size="mini"
            >
              <el-option
                v-for="item in UserFlagListss"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item> -->
      </el-form>
      <div class="dialog-footer" style="margin-bottom: 20px">
        <el-button
          type=""
          style="width:auto"
          @click="createData('postForm')"
        >
          Enterprise User Mount
        </el-button>
      </div>
    </el-dialog>
    <!-- SKU 弹窗 -->
    <el-dialog title="Choice Contract No" :visible.sync="dialogSKU" width="50%">
      <Delotal @handleCurrentChanges="handleCurrentChanges" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSKU = false">Cancel</el-button>
        <el-button
          
          type="primary"
          @click="updateDatas('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入API接口
import { getContractList, userBind, changeswitch } from '@/api/user/list';
import { getEnterpriseName } from '@/api/enterprise/enterprise';
import Delotal from './conttemplat.vue';
import dateFormate from '../../utils/date'
const defaultForm = {
  pin: '',
  contractNo: '',
  userType: '',
};
export default {
  // 引入局部刷新
  inject: ['reload'],
  components: {
    Delotal
  },
  filters:{
    filterDate(dateStr){
      return dateFormate(dateStr,'yyyy-MM-dd hh:mm:ss')
    }
  },
  data() {
    return {
      emptyImgSrc: '',
      postForm: Object.assign({}, defaultForm),
      // 配置分页信息
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0 // 总页数
      },
      // 搜索条件
      form: {
        contractNo: '',
        enterpriseId: null,
        contractType: 0,
        saler: '',
        businessAuditState: '',
        creditAuditState: '',
        state: null,
        // enterpriseName: ''
      },
      enterpriseNameCode: '',
      userFlag: '',
      dialogSKU: false,
      // 字典表
      contractTypeList: [{ value: 0, label: 'VSP' }],
      businessAuditStatusList: [
        { value: 1, label: 'Passed' },
        { value: 2, label: 'Rejected' },
        { value: 0, label: 'Waiting Audit' }
      ],
      creditControlAuditStatusList: [
        { value: 1, label: 'Passed' },
        { value: 2, label: 'Reject' },
        { value: 0, label: 'Waiting CreditVontrol Audit' }
      ],
      validityTypeList: [
        { value: 0, label: 'Valid' },
        { value: 1, label: 'invalid' }
      ],
      UserFlagList: [{ value: '1', label: 'VSP' }],
      ValidityList: [
        { value: 0, label: 'Main User' },
        { value: 1, label: 'Child User' }
      ],
      userTypeList: [
        { value: 1, label: 'Main User' },
        { value: 2, label: 'Child User' }
      ],
      // 后端获取表格信息
      tableList: [],
      Loadshow: false,
      textMap: {
        update: 'update',
        create: 'create'
      },
      dialogFormVisible: false,
      dialogStatus: 'update',
      rules: {
        pin: [
          {
            required: true,
            message: 'Please enter the Squence number',
            trigger: 'blur'
          }
        ],
        contractNo: [
          {
            required: true,
            message: 'Please enter the brand Name',
            trigger: 'blur'
          }
        ],
        userFlag: [
          {
            required: true,
            message: 'Please Choose User Flag',
            trigger: 'blur'
          }
        ],
        // userType: [
        //   {
        //     required: true,
        //     message: 'Please Choose User Type',
        //     trigger: 'blur'
        //   }
        // ],
        Loadright: false,
        multipleSelections: '',
      },
      EnterpriseList: [],
    };
  },
  watch: {
    dialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.$refs['postForm'].resetFields();
        this.postForm.userType = '';
      }
    }
  },
  created() {
    // 初始化表数据
    this.form.contractNo=this.$route.query.contractNo?this.$route.query.contractNo:''
    this.form.enterpriseId=this.$route.query.id ? Number(this.$route.query.id):null
    this.getFirstData();
    this.getEnterpriseNameData();
    this.emptyImgSrc = '';
  },
  methods: {
    // 切换企业名称，回显合同号
    changeEnterpriseFn(val) {
      this.form.contractNo = val;
    },
    // 根据企业名称查询 合同号
    getEnterpriseNameData() {
      getEnterpriseName().then(res => {
        if (res.code === 200) {
          var list = [];
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.contractNo != null) {
                list.push({
                  value: element.contractNo,
                  label: element.enterpriseName,
                  id: element.id
                });
              }
            }
            this.EnterpriseList = list;
          } else {
            this.EnterpriseList = [];
          }
        }
      });
    },
    handleCurrentChanges(val) {
      console.log(val);
      this.multipleSelections = val;
    },
    showSku() {
      this.dialogSKU = true;
    },
    updateDatas() {
      // console.log(this.multipleSelections);
      this.form.contractNo = this.multipleSelections.contractNo;
      this.dialogSKU = false;
    },
    loadshowBtn() {
      this.Loadshow = !this.Loadshow;
    },
    conBtn(row) {
      row.flang = 3;
      this.$router.push({
        name: 'Create a contract',
        path: '/contractManage/addContract',
        query: { flang:row.flang,enterpriseName:row.enterpriseName,id:row.id,contractNo:row.contractNo}
      });
    },
    changswitch(row) {
      var obj = {
        id: row.id,
        state: row.state
      };
      changeswitch(obj).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: 'Succeeded in changing the status',
            type: 'success'
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    },
    LoadrightBtn() {
      this.Loadright = !this.Loadright;
    },
    // 获取页面数据
    getFirstData() {
      getContractList(
        Object.assign(
          {
            pageNo: this.listQuery.pageCode,
            pageSize: this.listQuery.pageSize
          },
          this.form
        )
      ).then(res => {
        if (res.success && res.code === 200) {
          this.tableList = res.data.dataList;
          this.listQuery.total = res.data.totalCount;
        } else {
          // 暂无数据
          this.tableList = [];
          this.listQuery.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 新增
    addBtn() {
      this.$router.push({
        name: 'Create a contract',
        path: '/contractManage/addContract'
      });
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData();
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      this.listQuery.pageCode = 1;
      this.getFirstData();
    },
    // 搜索栏-清空按钮
    offBtn() {
      this.reload();
      this.enterpriseNameCode = '';
    },
    viewUser(row) {
      this.$router.push({
        name: 'users list',
        path: '/user/list',
        query: { contractNo:row.contractNo }
      });
    },
    bundleUser(row) {
      this.postForm.contractNo = row.contractNo;
      this.dialogFormVisible = true;
    },
    unbindUser(row) {
      this.$router.push({
        name: 'users list',
        path: '/user/list',
        query:  { contractNo:row.contractNo }
      });
    },
    createData() {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          let obj = {
            pin: this.postForm.pin,
            contractNo: this.postForm.contractNo,
            userType: this.postForm.userType
          }
          userBind(obj).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'successfully upload',
                type: 'success'
              });
              this.getFirstData();
              this.dialogFormVisible = false;
             
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    copy(contractNo) {
      this.$copyText(contractNo)
        .then(
          this.$message({
            message: 'Copy Success',
            type: 'success'
          })
        )
        .catch(err => {
          this.$message({
            message: 'Copy Failed',
            type: 'error'
          });
        });
    }
  }
};
</script>
<style lang="less" scoped>
.fixed_form {
  .el-input {
    width: 200px;
  }
}
.serch_box {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;

  ::v-deep .el-input__inner {
    height: 32px;
    width: 200px;
    line-height: 32px;
  }
}
.userList {
  ::v-deep .el-form-item__content {
    width: 230px;
  }
  ::v-deep .el-input__inner {
    height: 28px;
    line-height: 28px;
  }
  .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
    height: 45px;
  }
}
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/deep/.el-form-item__label {
  font-size: 14px !important;
  font-family: ArialMT;
}
.font_blue {
  color: #2262ff;
  cursor: pointer;
}
/deep/.el-button {
  // padding: 0 12px !important;
  font-size: 14px;
  width: 100px;
  font-family: ArialMT;
  ::v-deep .el-icon-search {
    font-size: 14px;
  }
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
  margin-left: 0;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}

::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
.panel-body {
  padding: 0 16px;
}
</style>
